import { Box, Button, CircularProgress, Grid } from '@material-ui/core';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { useEffect, useRef, useState, lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';
import {
  singleStepView,
  singleStepAction,
  downloadEvent
} from '../../commons/gtm-events';
import Headline from '../../components/headline/headline';
import Icon from '../../components/icon/icon';
import Paragraph from '../../components/paragraph/paragraph';
import { useStyles } from './thanksPage.jss';
import { v4 } from 'uuid';

const SectionSummary = lazy(() => import('../../sections/section-summary/sectionSummary'));

export default function ThanksPageContainer(props) {
  const { metaData, actions, config, location } = props;
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { vehicleDetails, availability, stepsData, vehicleModel } = useSelector(
    (state) => state.carBooking
  );
  const [loading, setLoading] = useState(true);
  const sanitizeInfoBox = sanitizeHtml(config.infoBox, {
    selfClosing: ['br']
  });
  const inputRef = useRef(null);
  const ics = require('ics');
  const { name, surname, email } = stepsData?.step_3;
  const { reservationDate } = location;
  const campaign = useSelector((state) => state.trackingDetails?.campaing);

  const buttons = [
    { text: 'pobierz w pdf', type: 'pdfDownload' },
    ...(reservationDate
      ? [{ text: 'dodaj do kalendarza google/outlook', type: 'saveToCalendar' }]
      : []),
    { text: 'dowiedz się więcej o modelu', type: 'goToModelDetails' }
  ];

  useEffect(() => {
    if (!vehicleDetails) history.push('/');
    else {
      setLoading(false);
      const gtmVars = {
        dealerName: vehicleDetails?.location?.name,
        dealerId: vehicleDetails?.location?.bir,
        vehicleModel: vehicleDetails?.modelName,
        vehicleId: vehicleDetails?.vehicleId,
      }
      singleStepView({
        pagePath: location.pathname,
        pageTitle: reservationDate ? 'book-a-test-drive|ML-BADT-ONLINE|5|complete' : 'request-a-test-drive|ML-BADT-ONLINE|2|complete',
        formType: reservationDate ? 'test_drive_booking' : 'test_drive_request',
        campaign,
        ...gtmVars
      });
      singleStepAction({
        formStepName:  'complete',
        formStepNumber: reservationDate ? 'step-5' : 'step-2',
        formType: reservationDate ? 'test_drive_booking' : 'test_drive_request',
        event: 'formCompleted',
        campaign,
        ...gtmVars
      });
    }
  }, [campaign, history, vehicleDetails, reservationDate, location.pathname]);

  useEffect(() => {
    if (vehicleModel) dispatch(actions.saveVehicleModel(null));
    dispatch(actions.setStepNumber(null));
  }, [actions, dispatch, vehicleModel]);

  const backToStepOne = () => {
    history.push('/');
    dispatch(actions.cleanUpSteps());
  };

  const actionButtons = ({ text, type }, index) => {
    const printDocument = async () => {
      html2canvas(inputRef.current, {
        logging: true,
        scale: 0.85,
        letterRendering: 1,
        useCORS: true,
        width: 1400,
        windowWidth: 1400
      }).then(async (canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({ orientation: 'landscape' });
        pdf.addImage(imgData, 'JPEG', 5, 5);
        const pdfName = `Jazda próbna ${vehicleDetails.modelDisplayName}.pdf`
        pdf.save(pdfName);
        downloadEvent({
            dealerName: vehicleDetails?.location?.name,
            dealerId: vehicleDetails?.location?.bir,
            vehicleModel: vehicleDetails?.modelName,
            vehicleId: vehicleDetails?.vehicleId,
            brochureName: pdfName,
            pageName: metaData.metaTitle,
          formType: reservationDate ? 'test_drive_booking' : 'test_drive_request',
          leadId: v4()
          });
      });
    };

    const actions = (type) => {
      switch (type) {
        case 'goToModelDetails':
          if (vehicleDetails?.modelInfoUrl)
            window.open(vehicleDetails?.modelInfoUrl, '_blank', 'noopener,noreferrer');
          break;
        case 'pdfDownload':
          printDocument();
          break;
        case 'saveToCalendar':
          const reservationDayAndHour = reservationDate?.split(' ');
          const [date, hour] = reservationDayAndHour || [];
          const dateToEvent = date.split('-');
          const hourToEvent = hour.split(':');

          const event = {
            start: [
              Number(dateToEvent[2]),
              Number(dateToEvent[1]),
              Number(dateToEvent[0]),
              Number(hourToEvent[0]),
              Number(hourToEvent[1])
            ],
            duration: { hours: 0, minutes: 30 },
            title: `Jazda próbna ${vehicleDetails?.modelName}`,
            description: `Dzień dobry,

  Zapraszamy na umówioną jazdę próbną Renault w salonie ${vehicleDetails?.location?.name} ${vehicleDetails?.location?.street} ${vehicleDetails?.location?.city}.

  Do zobaczenia!`,
            location: `${vehicleDetails?.location?.street}, ${vehicleDetails?.location?.city} ${vehicleDetails?.location?.region}`,
            geo: {
              lat: vehicleDetails?.location?.latitude,
              lon: vehicleDetails?.location?.longitude
            },
            categories: ['Jazda próbna'],
            status: 'CONFIRMED',
            busyStatus: 'BUSY',
            organizer: {
              name: `${vehicleDetails?.brandName}`,
              email: 'mail@renault.pl'
            },
            attendees: [
              {
                name: `${name} ${surname}`,
                email: email,
                rsvp: true,
                partstat: 'ACCEPTED',
                role: 'REQ-PARTICIPANT'
              }
            ]
          };
          ics.createEvent(event, (error, value) => {
            if (error) {
              console.log(error);
              return;
            }
            const blob = new Blob([value], {
              type: 'text/calendar;charset=utf-8'
            });
            saveAs(blob, `Jazda próbna ${vehicleDetails.modelDisplayName}.ics`);
          });
          break;
        default:
          break;
      }
    };

    return (
      <Button
        key={index}
        className={[
          classes.btn,
          {
            [classes.hide]: type === 'goToModelDetails' && !vehicleDetails?.modelInfoUrl
          }
        ]}
        color="primary"
        variant="outlined"
        onClick={() => actions(type)}>
        {text}
      </Button>
    );
  };

  return (
    <>
      <Helmet
        title={metaData.metaTitle}
        meta={[
          {
            name: 'description',
            content: metaData.metaDesc
          }
        ]}
      />
      {loading ? (
        <Box width="100%" display="flex" justifyContent="center" marginY={5}>
          <CircularProgress size={100} color="primary" />
        </Box>
      ) : (
        <Grid container className={classes.noBannerContainer}>
          <div>
            <Headline
              variant="h1"
              headline={config.headline}
              headlinePrimary={config.headlinePrimary}
              spaceBeetwen
              marginTop={3}
            />
            <Headline variant="h2" className={classes.subheadline} headline={config.subheadline} />
          </div>
          <div ref={inputRef}>
            <Box className={classes.infoBox}>
              <div>
                <Icon id="Check" size={46} marginRight={3} color="white" />
                <Paragraph color="primary">
                  <span dangerouslySetInnerHTML={{ __html: sanitizeInfoBox }} />
                </Paragraph>
              </div>
            </Box>
            <Suspense
              fallback={
                <Box width="100%" display="flex" justifyContent="center" marginY={5}>
                  <CircularProgress size={100} color="primary" />
                </Box>
              }>
              <SectionSummary
                vehicleDetails={vehicleDetails}
                availability={availability}
                translations={config}
                stepsData={stepsData}
                {...props}
              />
            </Suspense>
          </div>
          <div className={classes.actionButtons}>
            {buttons.map((item, index) => actionButtons(item, index))}
          </div>
          <div>
            <Button
              className={classes.btn}
              color="primary"
              variant="contained"
              onClick={() => backToStepOne()}>
              {config.goBackButton}
            </Button>
          </div>
        </Grid>
      )}
    </>
  );
}
