import { Box, CircularProgress, Grid } from '@material-ui/core';
import { useEffect, useState, lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { singleStepAction, singleStepView } from '../commons/gtm-events';
import { useStyles } from './steps.jss';
import { fetchVehicleDetails, setStepNumber, setStepper } from '../actions';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const SectionForm = lazy(() => import('../sections/section-form/sectionForm'));

export default function StepThree(props) {
  const { metaData, actions, config, location, match } = props;
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { vehicleDetails, availability, stepsData, finalBooking } = useSelector(
    (state) => state.carBooking
  );
  const slug = location?.state?.slug;
  const [loading, setLoading] = useState(true);
  const meta = {
    metaTitle: metaData(slug).metaTitle,
    metaDesc: metaData(slug).metaDesc
  };
  const campaign = useSelector((state) => state.trackingDetails?.campaing);

  useEffect(() => {
    if (!finalBooking) dispatch(setStepper(config.stepperShort));
    if (!vehicleDetails) dispatch(fetchVehicleDetails({ vin: match.params.vin }));
    else {
      setLoading(false);
      dispatch(setStepNumber(finalBooking ? 2 : 1)); // if date was chosen (finalBooking), show long stepper and save correct step number
      const gtmVars = {
        dealerName: vehicleDetails?.location?.name,
        dealerId: vehicleDetails?.location?.bir,
        vehicleModel: vehicleDetails?.modelName,
        vehicleId: vehicleDetails?.vehicleId,
      }
      singleStepView({
        pagePath: location.pathname,
        pageTitle: finalBooking ? 'book-a-test-drive|ML-BADT-ONLINE|2|Dane osobowe' : 'request-a-test-drive|ML-BADT-ONLINE|1|Dane osobowe',
        formType: finalBooking ? 'test_drive_booking' : 'test_drive_request',
          campaign,
        ...gtmVars
      });
      singleStepAction({
        formStepName:  'Dane osobowe',
        formStepNumber: finalBooking ? 'step-2' : 'step-1',
        formType: finalBooking ? 'test_drive_booking' : 'test_drive_request',
        event: 'formStep',
        campaign,
        ...gtmVars
      });
    }
  }, [
    vehicleDetails,
    history,
    dispatch,
    actions,
    campaign,
    config.stepperShort,
    finalBooking,
    match.params.vin,
    location.pathname
  ]);

  return (
    <>
      <Helmet
        title={meta.metaTitle}
        meta={[
          {
            name: 'description',
            content: meta.metaDesc
          }
        ]}
      />
      {loading ? (
        <Box width="100%" display="flex" justifyContent="center" marginY={5}>
          <CircularProgress size={100} color="primary" />
        </Box>
      ) : (
        <Grid container className={classes.container}>
          <Suspense
            fallback={
              <Box width="100%" display="flex" justifyContent="center" marginY={5}>
                <CircularProgress size={100} color="primary" />
              </Box>
            }>
            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
              <SectionForm
                vehicleDetails={vehicleDetails}
                availability={availability}
                stepsData={stepsData}
                translations={config}
                slug={slug}
                {...props}
              />
            </GoogleReCaptchaProvider>
          </Suspense>
        </Grid>
      )}
    </>
  );
}
